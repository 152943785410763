footer {
    background-color: #FFC500;
    width: 100%;
    display: flex;
    align-items: center;
}
footer > a {
    margin-left: 80px;
    overflow: hidden;
}
footer > a > img {
    height: inherit;
}
footer > div {
    margin-left: auto;
    margin-right: 80px;
    display: flex;
}
footer > div > a {
    color: #0058A1;
    text-decoration: none;
    margin-left: 15px;
    display: flex;
    align-items: center;
}
footer > div > a > img {
    margin-right: 5px;
    height: 25px;
    width: 25px;
}

/*4K SCREENS*/
@media (min-width: 1982px) {
    footer {
        height: 125px;
    }
    footer > a {
        height: 125px;
    }
    footer > div > a > span {
        font-size: 26px;
    }
}

/*STD*/
@media (max-width: 1981px) {
    footer {
        height: 75px;
    }
    footer > a {
        height: 75px;
    }
    footer > div > a > span {
        font-size: 20px;
    }
}

/*TABLET*/
@media (max-width: 1440px) {
    footer > div > a > span {
        font-size: 15px;
    }
}

@media (max-width: 769px) {
    footer > a {
        display: none;
    }
    footer > div {
        margin: auto;
    }
    footer > div > a {
        margin: 2px;
    }
    footer > div > a > span {
        font-size: 17px;
    }
    footer > div > a > img {
        height: 15px;
        width: 15px;
    }
}

/*MOBILE*/
@media (max-width: 426px) {
    footer > div > a > span {
        font-size: 3vw;
    }
}
