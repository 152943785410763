div#products {
    text-align: center;
}
div#products > div.slick-slider {
    margin: 50px auto;
    overflow: hidden;
}
div#products > h1 {
    margin-top: 50px;
    text-transform: uppercase;
}
div#products > p {
    text-align: justify;
    margin: 15px auto 0;
}

/*4K SCREENS*/
@media (min-width: 1982px) {
    div#products > p {
        width: 1200px;
        font-size: 30px;
    }
    div#products > div.slick-slider {
        width: 1200px;
        height: 754px;
    }
    div#products > h1 {
        font-size: 40px;
    }
}

/*STD*/
@media (max-width: 1981px) {
    div#products > p {
        width: 1200px;
        font-size: 20px;
    }
    div#products > div.slick-slider {
        width: 1200px;
        height: 754px;
    }
    div#products > h1 {
        font-size: 35px;
    }
}

@media (max-width: 1441px) {
    div#products > h1 {
        font-size: 30px;
    }
    div#products > div.slick-slider {
        width: 750px;
        height: 471px;
    }
    div#products > p {
        font-size: 15px;
        width: 750px;
    }
}

/*TABLET*/
@media (max-width: 769px) {
    div#products > div.slick-slider {
        width: 410px;
        height: 471px;
    }
    div#products > p {
        font-size: 18px;
        width: 410px;
    }
}

/*MOBILE*/
@media (max-width: 426px) {
    div#products > div.slick-slider {
        display: none;
    }
    div#products > p {
        width: 80vw;
    }
    div#products > div.separator {
        width: 70vw;
    }
}
