header {
    background-color: #FFC500;
    width: 100%;
    display: flex;
    align-items: center;
}
header > p {
    text-align: justify;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: auto;
    margin-right: 92px;
}
header > a > img {
    margin-left: 80px;
    height: inherit;
    width: inherit;
}
#skip-link {
    height: 40px;
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 100;
}
#skip-link:focus {
    top: 0;
}

/*4K SCREENS*/
@media (min-width: 1982px) {
    header {
        height: 125px;
    }
    header > p {
        font-size: 25px;
        width: 30%;
    }
    header > a {
        height: 125px;
        width: 420px;
    }
}

/*STD*/
@media (max-width: 1981px) {
    header {
        height: 75px;
    }
    header > p {
        font-size: 12px;
        width: 300px;
    }
    header > a {
        width: 300px;
        height: 75px;
    }
}

/*TABLET*/
@media (max-width: 769px) {
    header {
        display: flex;
        justify-content: center;
    }
    header > a > img {
        margin: 0;
    }
    header > p {
        font-size: 1.5vw;
        margin: 0;
        width: 30vw;
    }
}

/*MOBILE*/
@media (max-width: 426px) {
    header > a > img, header > a {
        margin: auto;
    }
    header > p {
        display: none;
    }
}

/*RIDICULOUSLY SMALL*/
@media (max-width: 321px) {
    header > a {
        width: 100vw;
    }
}
