div#contact > iframe#map {
    width: 100%;
    height: 35vh;
}

div#contact > div#values > h1 {
    text-align: center;
}

div#contact > div#values {
    display: grid;
    justify-content: center;
    margin-bottom: 50px;
}

div#contact > div#values > div.contact-field > * {
    display: inline-block;
    color: inherit;
}

div#contact > div#values > div.contact-field > div {
    width: 100px;
}

/*MOBILE*/
@media (max-width: 426px) {
    div#contact > iframe#map {
        height: 60vh;
    }
}

/*RIDICULOUSLY SMALL*/
@media (max-width: 280px) {
    div#contact > div#values > div.contact-field > * {
        font-size: 13px;
    }
}

