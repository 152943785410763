nav#navigation-buttons {
    background-color: #0058A1;
    display: flex;
    justify-content: center;
}
nav#navigation-buttons > a,
nav#navigation-buttons > div {
    padding: 0 10px;
    text-decoration: none;
    color: white;
    cursor: pointer;
}
nav#navigation-buttons > div {
    position: relative;
}
nav#navigation-buttons a:hover,
nav#navigation-buttons > div:hover {
    color: #0058A1;
    background-color: #FFC500;
}
nav#navigation-buttons > div:hover > div {
    display: block;
}
nav#navigation-buttons > div > div {
    position: absolute;
    display: none;
    left: 0;
    z-index: 1000;
    width: max-content;
}
nav > div > div > a {
    padding: 0 10px;
    display: block;
    color: white;
    text-decoration: none;
    background-color: #0058A1;
}

/*4K SCREENS*/
@media (min-width: 1982px) {
    nav#navigation-buttons {
        height: 125px;
        line-height: 125px;
        font-size: 25px;
    }
    nav#navigation-buttons > a,
    nav#navigation-buttons > div {
        height: 125px;
    }
    nav#navigation-buttons > div > div {
        top: 125px;
    }
    nav#navigation-buttons > div > div > a {
        height: 60px;
        line-height: 60px;
    }
}

/*STD*/
@media (max-width: 1981px) {
    nav#navigation-buttons {
        height: 75px;
        line-height: 75px;
        font-size: 19px;
    }
    nav#navigation-buttons > a,
    nav#navigation-buttons > div {
        height: 75px;
    }
    nav#navigation-buttons > div > div {
        top: 75px;
    }
    nav#navigation-buttons > div > div > a {
        height: 40px;
        line-height: 40px;
    }
}

/*TABLET*/
@media (max-width: 769px) {
    nav#navigation-buttons {
        font-size: 17px;
    }
}

/*MOBILE*/
@media (max-width: 426px) {
    nav#navigation-buttons {
        font-size: 3vw;
    }
}

/*RIDICULOUSLY SMALL*/
@media (max-width: 321px) {
    nav#navigation-buttons {
        height: 45px;
        line-height: 45px;
        font-size: 10px;
    }
    nav#navigation-buttons > a,
    nav#navigation-buttons > div {
        height: 45px;
    }
    nav#navigation-buttons > div > div {
        top: 45px;
    }
    nav#navigation-buttons > div > div > a {
        height: 30px;
        line-height: 30px;
    }
}
