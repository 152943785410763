body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #0058A1;
    font-family: 'Roboto', sans-serif;
}

body * {
    font-weight: normal;
}

div.separator {
    margin: auto;
    width: 300px;
    height: 8px;
    background-color: #FFC500;
}

@media (min-width: 1921px) {
    main#wrapper {
        min-height: calc(100vh - 375px);
    }
}
@media (max-width: 1921px) {
    main#wrapper {
        min-height: calc(100vh - 225px);
    }
}
