div#index {
    text-align: center;
}
div#index > div.slick-slider {
    margin: 50px auto auto;
    overflow: hidden;
}
div#index > h1 {
    margin-top: 50px;
    text-transform: uppercase;
}
div#index > h2 {
    max-width: 90vw;
    margin: 10px auto 0;
    text-transform: uppercase;
}
div#index > div#activities-container {
    display: flex;
    justify-content: center;
}
div#index > div#activities-container > div {
    padding: 40px;
}
div#index > div#activities-container > div > img {
    width: 85px;
}
div#index > div#activities-container > div > div {
    text-align: justify;
}

/*4K SCREENS*/
@media (min-width: 2001px) {
    div#index > div.slick-slider {
        width: 2000px;
        max-height: 835px;
    }
    div#index > h1 {
        font-size: 40px;
    }
    div#index > h2 {
        font-size: 30px;
    }
    div#index > div#activities-container > div {
        width: 500px;
        height: 400px;
        font-size: 30px;
    }
    div#index > div#activities-container > div > h3 {
        font-size: 35px;
    }
}

/*STD*/
@media (max-width: 1981px) {
    div#index > div.slick-slider {
        width: 1200px;
        max-height: 440px;
    }
    div#index > h1 {
        font-size: 25px;
    }
    div#index > h2 {
        font-size: 20px;
    }
    div#index > div#activities-container > div {
        width: 380px;
        height: 250px;
        font-size: 18px;
    }
    div#index > div#activities-container > div > h3 {
        font-size: 20px;
    }
}

@media (max-width: 1441px) {
    div#index > div.slick-slider {
        width: 750px;
        max-height: 275px;
    }
    div#index > h1 {
        font-size: 23px;
    }
    div#index > h2 {
        font-size: 19px;
    }
    div#index > div#activities-container > div {
        width: 350px;
        height: 250px;
        font-size: 15px;
    }
    div#index > div#activities-container > div > h3 {
        font-size: 20px;
    }
}

/*TABLET*/
@media (max-width: 769px) {
    div#index > div.slick-slider {
        width: 425px;
        max-height: 155px;
    }
    div#index > h1 {
        font-size: 23px;
    }
    div#index > h2 {
        font-size: 19px;
    }
    div#index > div#activities-container {
        display: block;
    }
    div#index > div#activities-container > div {
        margin: auto;
        width: 350px;
        height: 250px;
        font-size: 15px;
    }
    div#index > div#activities-container > div  > h3 {
        font-size: 20px;
    }
}

/*MOBILE*/
@media (max-width: 426px) {
    div#index > div.slick-slider {
        display: none;
    }
    div#index > div#activities-container > div {
        margin: auto;
        width: calc(90vw - 45px);
        height: 250px;
        font-size: 15px;
    }
    div#index > div.separator {
        width: 80vw;
    }
}