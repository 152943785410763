div#presentation, div#maintenance {
    text-align: center;
}
div#presentation > h1, div#maintenance > h1 {
    margin-top: 50px;
    text-transform: uppercase;
}
div#presentation > p, div#maintenance > p {
    text-align: justify;;
    margin: 15px auto 0;
}
div#presentation > ul, div#maintenance > ul {
    list-style: square;
    text-align: left;
    margin: 15px auto 15px;
}
div#presentation > img, div#maintenance > img {
    margin: 15px 0 30px 0;
}

@media (min-width: 1981px) {
    div#presentation > img, div#maintenance > img {
        width: 1200px;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 25px;
        width: 1200px;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 25px;
        width: 1200px;
    }
    div#presentation > h1, div#maintenance > h1 {
        font-size: 35px;
    }
}
@media (max-width: 1981px) {
    div#presentation > img, div#maintenance > img {
        width: 1000px;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 18px;
        width: 1000px;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 18px;
        width: 1000px;
    }
    div#presentation > h1, div#maintenance > h1 {
        font-size: 30px;
    }
}
@media (max-width: 1441px) {
    div#presentation > img, div#maintenance > img {
        width: 800px;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 15px;
        width: 800px;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 15px;
        width: 800px;
    }
    div#presentation > h1, div#maintenance > h1 {
        font-size: 25px;
    }
}
@media (max-width: 1025px) {
    div#presentation > img, div#maintenance > img {
        width: 700px;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 15px;
        width: 700px;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 15px;
        width: 700px;
    }
}
@media (max-width: 769px) {
    div#presentation > img, div#maintenance > img {
        width: 400px;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 15px;
        width: 400px;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 15px;
        width: 400px;
    }
    div#presentation > h1, div#maintenance > h1 {
        font-size: 20px;
    }
}
@media (max-width: 426px) {
    div#presentation > img, div#maintenance > img {
        width: 350px;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 15px;
        width: 350px;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 15px;
        width: 350px;
    }
}
@media (max-width: 376px) {
    div#presentation > img, div#maintenance > img {
        width: 270px;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 15px;
        width: 270px;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 15px;
        width: 270px;
    }
}
@media (max-width: 321px) {
    div#presentation > img, div#maintenance > img {
        width: 90vw;
    }
    div#presentation > ul, div#maintenance > ul {
        font-size: 15px;
        width: 90vw;
    }
    div#presentation > p, div#maintenance > p {
        font-size: 15px;
        width: 90vw;
    }
    div#presentation > div.separator {
        width: 90vw;
    }
}

